import { ProcessingStatus } from 'gql/generated/types-and-hooks';

export const getProcessingStatusToDisplay = ({
  processingStatus,
  isAdmin,
}: {
  processingStatus: ProcessingStatus;
  isAdmin: boolean;
}): ProcessingStatus => {
  if (isAdmin) {
    return processingStatus;
  }
  switch (processingStatus) {
    case ProcessingStatus.Submitted:
    case ProcessingStatus.InReview:
    case ProcessingStatus.WaitListed:
    case ProcessingStatus.Assigned:
    case ProcessingStatus.Rejected:
      return ProcessingStatus.Submitted;
    case ProcessingStatus.RejectedConfirmed:
      return ProcessingStatus.Rejected;
    case ProcessingStatus.Finished:
    case ProcessingStatus.FinishedCancelled:
    case ProcessingStatus.FinishedCancelledConfirmed:
    case ProcessingStatus.FinishedRejectedConfirmed:
      return ProcessingStatus.Finished;
    case ProcessingStatus.Cancelled:
    case ProcessingStatus.CancelledConfirmed:
      return ProcessingStatus.Cancelled;
    default:
      return processingStatus;
  }
};
