import {
  ApplicationStatisticsQuery,
  ProcessingStatus,
  StatisticsField,
} from 'gql/generated/types-and-hooks';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import autocolors from 'chartjs-plugin-autocolors';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend, autocolors);

const LIST_OF_PROCESSING_STATUS = [
  ProcessingStatus.Created,
  ProcessingStatus.SentBack,
  ProcessingStatus.Submitted,
  ProcessingStatus.InReview,
  ProcessingStatus.WaitListed,
  ProcessingStatus.Rejected,
  ProcessingStatus.Cancelled,
  ProcessingStatus.Assigned,
  ProcessingStatus.Confirmed,
  ProcessingStatus.RejectedConfirmed,
  ProcessingStatus.CancelledConfirmed,
  ProcessingStatus.FinishedCancelled,
  ProcessingStatus.FinishedRejectedConfirmed,
  ProcessingStatus.FinishedCancelledConfirmed,
  ProcessingStatus.Finished,
];

export function Statistics({
  statistics,
}: {
  statistics: ApplicationStatisticsQuery['applicationStatistics'];
}): ReactElement {
  const chart = useRef<ChartJS<'doughnut'>>();
  const { t } = useTranslation();
  const [highlightedStatus, setHighlightedStatus] =
    useState<ProcessingStatus | null>(null);
  const currentYear = statistics.currentYear;

  const getData = useCallback(
    (status: ProcessingStatus, category: keyof StatisticsField) => {
      if (statistics == null) return '---';
      return statistics[status][category];
    },
    [statistics],
  );

  const chartData = useMemo(
    () => ({
      labels: LIST_OF_PROCESSING_STATUS.map(status => {
        return t(`pages.myApplications.processingStatus.${status}`);
      }),
      datasets: [
        {
          label: ' # of Applications',
          data: LIST_OF_PROCESSING_STATUS.map(status => {
            if (statistics == null) return 0;
            return Object.values(statistics[status]).reduce<number>(
              (acc, val) =>
                Number.isInteger(val) ? acc + (val as number) : acc,
              0,
            );
          }),
        },
      ],
    }),
    [t, statistics],
  );

  return (
    <div className="grid grid-cols-3 gap-12">
      <table className="col-span-2 w-full table-fixed">
        <colgroup>
          <col className="w-1/3" />
        </colgroup>
        <thead className="whitespace-nowrap">
          <tr>
            <th />
            <th colSpan={6} className="text-center">
              {t('components.settings.maintenance.applicationDate')}
            </th>
          </tr>
          <tr className="border-b border-grey">
            <th>{t('components.settings.maintenance.processingStatus')}</th>
            <th>&lt;= {currentYear - 5}</th>
            <th>{currentYear - 4}</th>
            <th>{currentYear - 3}</th>
            <th>{currentYear - 2}</th>
            <th>{currentYear - 1}</th>
            <th>{currentYear}</th>
          </tr>
        </thead>
        <tbody>
          {LIST_OF_PROCESSING_STATUS.map((status, index) => {
            return (
              <tr
                key={status}
                className={`${
                  status === highlightedStatus
                    ? 'bg-gray-100 font-semibold text-primary'
                    : ''
                } hover:bg-gray-100 hover:font-semibold hover:text-primary`}
                onMouseOver={() => {
                  if (chart.current == null) return;

                  const tooltip = chart.current.tooltip;
                  if (tooltip == null) return;

                  const chartArea = chart.current.chartArea;
                  tooltip.setActiveElements(
                    [
                      {
                        datasetIndex: 0,
                        index: index,
                      },
                    ],
                    {
                      x: (chartArea.left + chartArea.right) / 2,
                      y: (chartArea.top + chartArea.bottom) / 2,
                    },
                  );

                  chart.current.update();
                }}
              >
                <td>{t(`pages.myApplications.processingStatus.${status}`)}</td>
                <td>{getData(status, 'OLDER_THAN_FOURTH_LAST_YEAR')}</td>
                <td>{getData(status, 'FOURTH_LAST_YEAR')}</td>
                <td>{getData(status, 'THIRD_LAST_YEAR')}</td>
                <td>{getData(status, 'SECOND_LAST_YEAR')}</td>
                <td>{getData(status, 'LAST_YEAR')}</td>
                <td>{getData(status, 'CURRENT_YEAR')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="relative flex h-full items-center">
        <Doughnut
          ref={chart}
          data={chartData}
          options={{
            onHover: (event, elements) => {
              if (event.type === 'mousemove' && elements.length > 0) {
                setHighlightedStatus(
                  LIST_OF_PROCESSING_STATUS[elements[0].index],
                );
              } else {
                setHighlightedStatus(null);
              }
            },
            plugins: {
              legend: {
                position: 'bottom',
              },
              autocolors: {
                mode: 'data',
              },
            },
          }}
        />
      </div>
    </div>
  );
}
